import * as React from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-hook-inview";
import { useCountUp } from "react-countup";
import shield from "../images/shield.png"
import apartmentBlocks from "../images/apartment-blocks.png"
import check from "../images/check.png"
import { useWindowSize } from "../hooks/useWindowSize";

const textInLeft = {
  hidden: {
    opacity: 0,
    x: -40,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.8,
    },
    x: 0,
  },
};

const textInRight = {
  hidden: {
    opacity: 0,
    x: +40,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.8,
    },
    x: 0,
  },
};

const textInStandard = {
  hidden: {
    opacity: 0
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.8,
    },
    x: 0,
  },
}

const icons = [
  {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.8,
      },
      x: 0,
    },
  },
  {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 1.6,
      },
      x: 0,
    },
  },
  {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 2.4,
      },
      x: 0,
    },
  },
];

const MetricsSection = () => {
  const windowSize = useWindowSize()
  const controls = useAnimation();
  const [ref, inView] = useInView();
  const [firstInView, setFirstInView] = React.useState(false);

  const CountUp = ({ end, refference }) => {
    useCountUp({ ref: refference, start: firstInView === true ? end : 0, end: end, duration: 1.7 });
    return <span className="mr-2" id={refference} />;
  };

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
      if(firstInView === false)
        setTimeout(() => setFirstInView(true), 1600)
    }
  }, [controls, inView, firstInView]);

  if(windowSize.width > 900) return (
    <div
      style={{
        height: 920,
      }}
      className={`
        bg-white
       py-96`}
    >
      <div
        className={`px-10 py-4 text-5xl 
        text-black
         mx-auto`}
      >
        <div ref={ref} className="flex justify-center w-full space-x-16">
          <div className="font-bold uppercase invisible">Despre noi</div>
        </div>
      </div>
      <div className="flex justify-center  w-full">
        <div
          className={`flex flex-row w-full justify-between 
            text-black
           text-2xl w-8/12 space-x-48`}
        >
          <div className="flex justify-center w-full">
            <div className="flex justify-between w-10/12 h-96">
              <motion.div className="flex flex-col space-y-20 text-right w-5/12">
                <motion.div initial="hidden" animate={inView && "visible"} variants={textInLeft} className="font-Holtwood text-5xl"> <div className="mt-1"> INCREDERE </div> </motion.div>
                <motion.div initial="hidden" animate={inView && "visible"} variants={textInLeft} className="font-Holtwood text-5xl"> JUSTITIE </motion.div>
                <motion.div initial="hidden" animate={inView && "visible"} variants={textInLeft} className="font-Holtwood text-5xl h-90"> <div className="mt-6"> SIGURANTA </div> </motion.div>
              </motion.div>
              <div className="flex justify-center h-90">
                <motion.div className="flex flex-col space-y-20 h-full">
                  <div className="h-90 relative">
                  <motion.img
                    initial="hidden"
                    animate={firstInView && "visible"}
                    variants={icons[0]}
                    className="h-14 w-14 rounded-lg "
                    src={shield}
                  />
                  </div>
                  <div className="h-90 relative">
                  <motion.img
                    initial="hidden"
                    animate={firstInView && "visible"}
                    variants={icons[1]}
                    className="absolute bottom-0.5 -top-3 h-14 w-14 rounded-lg"
                    src={apartmentBlocks}
                  />
                  </div>
                  <div className="h-90 relative">
                  <motion.img
                    initial="hidden"
                    animate={firstInView && "visible"}
                    variants={icons[2]}
                    className="absolute top-11 h-14 w-14 mt-5 rounded-lg"
                    src={check}
                  />
                  </div>
                </motion.div>
              </div>
              <motion.div className="flex flex-col space-y-20 text-left w-5/12">
                <motion.div initial="hidden" animate={inView && "visible"} variants={textInRight} className="font-Holtwood text-5xl w-128"> <div className="mt-1"> <CountUp end={1200} refference="litigii" /> litigii </div> </motion.div>
                <motion.div initial="hidden" animate={inView && "visible"} variants={textInRight} className="font-Holtwood text-5xl w-128"> <CountUp end={950} refference="procese" /> procese </motion.div>
                <motion.div initial="hidden" animate={inView && "visible"} variants={textInRight} className="font-Holtwood text-5xl w-80">
                  {" "}
                  <CountUp end={29} refference="ani" /> ani de practica{" "}
                </motion.div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  else return(
    <div ref={ref} className="bg-white py-96" style={{height: 880}}>
      <div className="flex justify-center text-center mt-2">
      <div className="flex flex-col space-y-20">
        <motion.div initial="hidden" animate={inView && "visible"} variants={textInStandard} className="flex flex-col space-y-6">
          <div className="font-Holtwood text-2xl">
          <CountUp end={1200} refference="litigii" /> de litigii
          </div>
          <div className="flex flex-row space-x-4">
            <div className="font-Holtwood text-lg px-9">
              Incredere
            </div>
            <img className="w-9 h-9 my-auto" src={shield} />
          </div>
        </motion.div>
        <motion.div initial="hidden" animate={inView && "visible"} variants={textInStandard} className="flex flex-col space-y-6">
          <div className="font-Holtwood text-2xl">
          <CountUp end={950} refference="procese" /> de procese
          </div>
          <div className="flex flex-row space-x-10">
            <div className="font-Holtwood text-lg px-9">
              Justitie
            </div>
            <img className="w-9 h-9 my-auto" src={apartmentBlocks} />
          </div>
        </motion.div>
        <motion.div initial="hidden" animate={inView && "visible"} variants={textInStandard} className="flex flex-col space-y-6">
          <div className="font-Holtwood text-2xl">
          <CountUp end={29} refference="ani" /> ani experienta
          </div>
          <div className="flex flex-row space-x-3">
            <div className="font-Holtwood text-lg px-9">
              Siguranta
            </div>
            <img className="w-9 h-9 my-auto" src={check} />
          </div>
        </motion.div>
      </div>
      </div>
    </div>
  )
};

export default MetricsSection;
