import * as React from "react"
import { useWindowSize } from "../hooks/useWindowSize"
import signer from "../images/signer.png"

const TextSection = () => {
  const windowSize = useWindowSize()
  return <div className="flex justify-center w-full">
    <div className="relative w-full">
      <div className={`absolute bg-transparent py-14 w-full bottom-10 -top-32`}>
            <div className="flex w-full justify-center">
              <div className={`w-11/12 lg:w-banner bg-white text-black rounded-lg shadow-lg h-full pt-5`}>
                <div className="flex flex-row w-full h-full">
                  <div className={`flex flex-col ${windowSize.width > 900 ? "w-1/2" : ""} pl-16 py-10`}>
                    <div className="flex flex-col space-y-2">
                    <div className="font-normal uppercase text-xl">
                      Despre Noi
                    </div>
                    <div className="h-1 w-10 bg-gold my-auto"></div>
                    </div>
                    <p className={`block py-6 ${windowSize.width > 900 ? "w-96" : "w-64 "} `}> 
                    Cabinetul de avocatură Carmen Lazăr a fost înființat în anul 1997, avocatul titular fiind înscris în Baroul București din anul 1992.
                    </p>
                    <div className="py-4 w-44"> 
                      <img src={signer} alt="signer" />
                    </div>
                    <div className="uppercase font-light tracking-widest py-2 text-sm text-opacity-50 text-gray-900">
                      Carmen Lazar, Avocat
                    </div>
                  </div>
                  {windowSize.width > 900 && <div className="flex w-1/2"> <div id="avocat" className="w-full rounded-lg"> </div>
                  </div>}
                  <div></div>
                </div>
              </div> 
            </div>
      </div>
    </div>
  </div>
}

export default TextSection
