import * as React from "react";
import {useWindowSize} from "../hooks/useWindowSize";

const HeroSection = () => {
  const windowSize = useWindowSize()
  return (
    <div
      style={windowSize > 500 ?{
        height: 950,
      } : {height: 800}}
    >
      <div
        id={"banner"}
        className="relative h-full w-full"
      >
        <div className="absolute bottom-96 text-white w-full top-80">
          <div className="flex w-full justify-center">
            <div className="flex flex-col space-y-10">
              <div className="flex flex-col space-y-2">
                <div className="flex w-full justify-center text-gold-2 text-4xl uppercase tracking-wider text-center">
                  <div className="px-2">Cabinet Avocatura</div>
                </div>
                <div className="flex w-full justify-center">
                  <div className="h-0.5 w-24 bg-gold my-auto"></div>
                </div>
              </div>
              <div className="flex flex-col space-y-6">
                <div className="flex justify-center sm:text-8xl text-5xl font-Merriweather font-black uppercase tracking-wider">
                  <div className="text-center">Carmen Lazar</div>
                </div>
                <p className="flex justify-center italic text-lg text-center tracking-wide">
                    <span className="w-72 sm:w-full"> Siguranta si profesionalism din 1992</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
