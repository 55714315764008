import * as React from "react"
import { useWindowSize } from "../hooks/useWindowSize"

const DescriptionSection = () => {
    const windowSize = useWindowSize()

    if(windowSize.width > 700) return <div>
        <div id="about" className={`flex justify-center py-10 px-20 bg-black text-white  w-full`}>
            <div className="flex justify-center w-full border border-gold">
            <div className="w-10/12 text-3xl px-8 py-20 text-center">
                Având o experiență dovedită în cei peste 29 de ani de practică, considerăm că o consultație juridică poate fi de multe ori utilă în găsirea celei mai bune soluții pentru problema dvs, evitând timpul de așteptare și costurile suplimentare ale unui litigiu.
            </div>
            </div>
        </div>
    </div>
    else return <div id="about" className={`flex justify-center py-10 px-8 bg-black text-white font-light w-full`}>
        <div className="flex justify-center w-full border border-gold ">
        <div className="space-y-2 text-2xl px-2 py-14 text-center w-80">
            Având o experiență dovedită în cei peste 29 de ani de practică, considerăm că o consultație juridică poate fi de multe ori utilă în găsirea celei mai bune soluții pentru problema dvs, evitând timpul de așteptare și costurile suplimentare ale unui litigiu.
        </div>
        </div>
    </div>
}

export default DescriptionSection